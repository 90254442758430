import React from 'react'
import { myContext } from '../../../provider'
import { SortAscendingIcon, MailIcon } from '@heroicons/react/solid'
import Axios from "axios"
import { Link, navigate } from "gatsby"
const ForgotPassword = () => {

    function sendForgetPassword(e) {

      e.preventDefault();
      console.log(e);
      const data = {
        email: e.target.email.value
      };
      
      Axios.post('https://api.gogoblock.io/api/forget-password', data)
        .then((result) => {
          navigate(`/auth/email-reset-sent`);

        }).catch((err) => {
            console.error(err);
        });
    }

    return (
        <myContext.Consumer>
            {context => (
                <>
                    <div className="w-1/2 mx-auto h-screen flex flex-col">
                        
                        <form className="space-y-6 w-full mt-20" action="#" method="POST" onSubmit={sendForgetPassword}>
                            <div>
                              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                              Forgot password? Enter the email address associated with your account. <br />
                                We will send you a link to reset your password.
                              </label>
                              <div className="mt-3 flex rounded-md shadow-sm">
                                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                                  <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-1 sm:text-sm border-gray-300"
                                    placeholder="Email address"
                                  />
                                </div>
                                <button
                                  type="submit"
                                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-200 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                >
                                  <MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  <span>Send</span>
                                </button>
                              </div>
                            </div>
                        </form>
                        
                    </div>
                </>
            )}
        </myContext.Consumer>
    )

}

export default ForgotPassword